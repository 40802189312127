import React from 'react';
import Home from "./component/home/home";
import {Provider} from 'react-redux';
import store from './istore'
import {Route, Switch, BrowserRouter} from "react-router-dom";
import Video from "./component/video/video";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "./component/header/header.component";
import SideBar from "./component/sidebar/sidebar.component";
import {appStyles} from "./App.styles";
import Footer from "./component/footer/footer.component";

function App() {
    const classes = appStyles();
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Provider store={store}>
                <BrowserRouter>
                    <Header open={open} handleDrawerOpen={handleDrawerOpen}/>
                    <SideBar open={open} handleDrawerClose={handleDrawerClose}/>
                    <Switch>
                        <Route path="/watch" component={Video}/>
                        <Route component={Home}/>}/>
                    </Switch>
                    <Footer/>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

export default App;
