import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Settings as SettingsIcon} from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {settingsStyles} from "./settings.styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default function Settings() {
    const classes = settingsStyles();
    const REPEAT_WITH_SUBTITLE = 'repeatWithSubtitle';
    const REPEAT_WITHOUT_SUBTITLE = 'repeatWithoutSubtitle';
    const [open, setOpen] = React.useState(false);

    const getWithSubtitleInitialState = () => {
        return localStorage.getItem(REPEAT_WITH_SUBTITLE) || 2;
    }

    const getWithoutSubtitleInitialState = () => {
        return localStorage.getItem(REPEAT_WITHOUT_SUBTITLE) || 1;
    }

    const [withSubtitleValue, setWithSubtitleValue] = React.useState(getWithSubtitleInitialState());

    const [withoutSubtitleValue, setWithoutSubtitleValue] = React.useState(getWithoutSubtitleInitialState());

    const handleWithSubtitleChange = (event) => {
        setWithSubtitleValue(event.target.value);
    }

    const handleWithoutSubtitleChange = (event) => {
        setWithoutSubtitleValue(event.target.value);
    }

    const toggle = () => {
        setWithSubtitleValue(getWithSubtitleInitialState())
        setWithoutSubtitleValue(getWithoutSubtitleInitialState())
        setOpen(!open);
    };

    const saveChanges = () => {
        localStorage.setItem(REPEAT_WITH_SUBTITLE, withSubtitleValue);
        localStorage.setItem(REPEAT_WITHOUT_SUBTITLE, withoutSubtitleValue);
        toggle();
    }

    return (
        <div>
            <ListItem button onClick={toggle}>
                <ListItemIcon>
                    <SettingsIcon/>
                </ListItemIcon>
                <ListItemText primary="Settings"/>
            </ListItem>
            <Dialog onClose={toggle} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}>
                <DialogTitle id="customized-dialog-title" onClose={toggle}>
                    Repeat each sentence
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <FormControl className={classes.formControl}>
                            <Grid container>
                                <Select className={classes.select} labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={withoutSubtitleValue}
                                        defaultValue={withoutSubtitleValue}
                                        onChange={handleWithoutSubtitleChange}>
                                    <MenuItem value={0}>Zero</MenuItem>
                                    <MenuItem value={1}>One</MenuItem>
                                    <MenuItem value={2}>Two</MenuItem>
                                    <MenuItem value={3}>Three</MenuItem>
                                </Select>
                                <Grid item>
                                    time(s) without subtitle
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Typography>
                    <Typography gutterBottom>
                        <FormControl className={classes.formControl}>
                            <Grid container alignItems="center">
                                <Select className={classes.select} labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={withSubtitleValue}
                                        onChange={handleWithSubtitleChange}>
                                    <MenuItem value={0}>Zero</MenuItem>
                                    <MenuItem value={1}>One</MenuItem>
                                    <MenuItem value={2}>Two</MenuItem>
                                    <MenuItem value={3}>Three</MenuItem>
                                </Select>
                                <Grid item>
                                    time(s) with subtitle
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggle} color="primary">
                        Cancel
                    </Button>
                    <Button autoFocus onClick={saveChanges} color="primary">
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}