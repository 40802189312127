const setValue = (key, value) => {
    localStorage.setItem(key, value);
}

const getValue = (key) => {
    return localStorage.getItem(key);
}

const clear = () => {
    localStorage.clear();
}

const removeItem = (key) => {
    localStorage.removeItem(key);
}

export {setValue, getValue, clear, removeItem}