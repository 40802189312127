import React, {Component} from 'react';

class Video extends Component {

    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
    }

    render() {
        return (
            <div>
                <iframe title="video" width="560" height="315" src="https://www.youtube.com/embed/1t6gP8BhZqw" frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
        );
    }
}

Video.propTypes = {

};

export default Video;