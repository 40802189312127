import {GET_TRANSLATED_WORD} from './type'
import {translate} from "../component/video/dictionary.lib";

export const getTranslateWord = (word) => (dispatch) => {
    translate(word).then((translatedWord) => {
        dispatch({
            type: GET_TRANSLATED_WORD,
            data: translatedWord,
        });
    })
};
