import makeStyles from "@material-ui/core/styles/makeStyles";

export const settingsStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    select: {
        marginRight:20,
        fontSize:15
    }
}));