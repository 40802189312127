import React from "react";
import {getTranslateWord} from "../../action/getTranslateWord";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";

function Dictionary() {
    const item = useSelector(state => state.dictionary);
    const dispatch = useDispatch();

    return (
        <div>
            <button onClick={() => dispatch(getTranslateWord("hi"))}>translate now</button>
            <h1>{item.translation}</h1>
        </div>

    );
}

export default Dictionary;