import {gapi} from "gapi-script";

const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest', 'https://sheets.googleapis.com/$discovery/rest?version=v4'];
const SPREAD_SHEET_MIMETYPE = 'application/vnd.google-apps.spreadsheet';

const initStorage = () => {
    return gapi.client.init({discoveryDocs: DISCOVERY_DOCS});
};

const list = () => {
    return gapi.client.drive.files.list({fields: 'files(name, id)',});
};

const create = (filename) => {
    return gapi.client.drive.files.create({
        name: filename,
        mimeType: SPREAD_SHEET_MIMETYPE
    });
}

const loadFile = (fileId) => {
    return gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: fileId,
        range: 'A:C',
    });
}

const appendFile = (fileId, key, value) => {
    return gapi.client.sheets.spreadsheets.values.append({
        "spreadsheetId": fileId,
        "range": "A:B",
        "insertDataOption": "INSERT_ROWS",
        "includeValuesInResponse": false,
        "valueInputOption": "USER_ENTERED",
        "values": [[key, value]]
    });
}

const updateFile = (fileId, key, value, range) => {
    return gapi.client.sheets.spreadsheets.values.update({
        "spreadsheetId": fileId,
        "range": range,
        "includeValuesInResponse": false,
        "valueInputOption": "USER_ENTERED",
        "values": [[key, value]]
    });
}

const loadGapi = () => {
    const load = new Promise((resolve => {
        gapi.load('client:auth2', resolve);
    }));
    return load.then(() => {
        return initStorage();
    });
}
export {loadGapi, create, list, loadFile, appendFile, updateFile};