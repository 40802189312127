import {GET_TRANSLATED_WORD} from '../action/type';

const initialState = {
    data: ""
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TRANSLATED_WORD:
            return {
                ...state,
                translation: action.data
            };
        default:
            return state;
    }
}