import React from 'react'
import {GoogleLogin, GoogleLogout} from 'react-google-login';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PeopleIcon from "@material-ui/icons/People";
import ListItemText from "@material-ui/core/ListItemText";
import {ExitToApp} from "@material-ui/icons";
import loadAccount from "./account.lib";
import {getValue, removeItem, setValue} from "../../../storage/local.lib";
import {CONFIG_FILE_ID, WORDS_FILE_ID} from "../../../config/name.config";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const SCOPES = 'https://www.googleapis.com/auth/drive.file';
const IS_LOGINED = 'isLogined';

export default function Account() {
    const [isLogined, setIsLogined] = React.useState(getValue(IS_LOGINED) === "true" || false);
    React.useEffect(() => {
        setValue(IS_LOGINED, isLogined);
        removeItem(CONFIG_FILE_ID);
        removeItem(WORDS_FILE_ID);
    }, [isLogined]);

    const login = (response) => {
        setIsLogined(true);
        loadAccount();
    }

    const logout = (response) => {
        setIsLogined(false);
    }

    const handleLoginFailure = (response) => {
        console.log(JSON.stringify(response))
    }

    const handleLogoutFailure = (response) => {
        console.log(JSON.stringify(response))
    }
    return (
        <div>
            {isLogined ?
                <GoogleLogout
                    clientId={CLIENT_ID}
                    render={renderProps => (
                        <ListItem button onClick={renderProps.onClick} disabled={renderProps.disabled}>
                            <ListItemIcon>
                                <ExitToApp/></ListItemIcon><ListItemText primary="Logout"/>
                        </ListItem>
                    )}
                    onFailure={handleLogoutFailure}
                    onLogoutSuccess={logout}
                >
                </GoogleLogout> :
                <GoogleLogin
                    clientId={CLIENT_ID}
                    scope={SCOPES}
                    render={renderProps => (
                        <ListItem button onClick={renderProps.onClick} disabled={renderProps.disabled}>
                            <ListItemIcon>
                                <PeopleIcon/></ListItemIcon><ListItemText primary="Login"/>
                        </ListItem>
                    )}
                    onSuccess={login}
                    onFailure={handleLoginFailure}
                    cookiePolicy={'single_host_origin'}
                />}
        </div>
    )
}
