import React from 'react';
import Search from "./search/search.component";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {homeStyles} from "./home.styles";
import {addWord} from "../sidebar/account/words.lib";
import Dictionary from "../dictionary/dictionary";

const onAddWord = () => {
    addWord("some word");
}

export default function Dashboard() {
    const classes = homeStyles()
    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer}/>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Search/>
                    </Grid>
                </Grid>
                <Dictionary/>
                <br/>
                <div onClick={onAddWord}>add word</div>
            </Container>
        </main>
    );
}