import {appendFile, create} from "../../../storage/gapi.lib";
import {WORDS_FILE_ID} from "../../../config/name.config";
import {getValue, setValue} from "../../../storage/local.lib";

const WORDS = process.env.REACT_APP_WORDS_FILE_NAME;

const setWordsFileValues = (files) => {
    const file = files.find(file => file.name === WORDS);
    if (file) {
        setValue(WORDS_FILE_ID, file.id);
    } else {
        create(WORDS).then((response) => {
            setValue(WORDS_FILE_ID, response.result.id);
        });
    }
}

const addWord = (word) => {
    const wordFileId = getValue(WORDS_FILE_ID);
    appendFile(wordFileId, word, "").then(() => {
    });
}

export {setWordsFileValues, addWord}
