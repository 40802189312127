import React from "react";
import {useHistory} from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Account from "../account/account.component";
import Settings from "../../home/settings/settings.component";

export default function MainListItems() {
    const history = useHistory();
    const routeChange = (path) => {
        history.push(path);
    }
    return (
        <div>
            <ListItem button onClick={() => routeChange('/')}>
                <ListItemIcon>
                    <DashboardIcon/>
                </ListItemIcon>
                <ListItemText primary="Home"/>
            </ListItem>
            <Account/>
            <Settings/>
        </div>
    )
}
