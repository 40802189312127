import { GET_YOUTUBE_ID } from '../action/type';

const initialState = {
    ids: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_YOUTUBE_ID:
            return {
                ...state,
                ids: action.ids
            };
        default:
            return state;
    }
}