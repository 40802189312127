const url = process.env.REACT_APP_DICTIONARY_URL;
const key = process.env.REACT_APP_DICTIONARY_KEY;

const translate = (word) => {
    const fullAddress = url.concat(word).concat(`?key=${key}`);
    return new Promise(resolve => {
        return fetch(fullAddress).then(r => r.json().then(response => resolve(response[0]['shortdef'][0])));
    })
}

export {translate}