import React from "react";
import {useHistory} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import {ArrowForward} from "@material-ui/icons";
import {searchStyles} from "./search.styles";
import TextField from "@material-ui/core/TextField";

export default function Search() {
    const classes = searchStyles();
    const history = useHistory();
    const [url, setUrl] = React.useState("https://www.youtube.com/");
    const [hasError, setHasError] = React.useState(false);
    const routeChange = () => {
        let parameter = url.match(/(http[s]?:\/\/)?([^/\s]+youtube.com\/)(.*)/);
        if (parameter && parameter[3].length > 0) {
            history.push(parameter[3]);
        } else {
            setHasError(true);
        }
    }

    const handleSetUrl = (event) => {
        setUrl(event.target.value);
    }

    return (
        <Paper component="form" className={classes.root}>
            <TextField
                className={classes.input}
                error={hasError}
                aria-errormessage="Please enter valid Youtube link"
                placeholder='Paste Youtube Link Here To Start'
                onChange={handleSetUrl}
            />
            <IconButton type="button" className={classes.iconButton} aria-label="start"
                        onClick={routeChange}>
                <ArrowForward/>
            </IconButton>
        </Paper>
    )
}