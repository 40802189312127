import {create, loadFile, updateFile} from "../../../storage/gapi.lib";
import {
    CONFIG_FILE_ID,
    REPEAT_WITH_SUBTITLE, REPEAT_WITH_SUBTITLE_VALUE,
    REPEAT_WITHOUT_SUBTITLE, REPEAT_WITHOUT_SUBTITLE_VALUE,
} from "../../../config/name.config";
import {setValue} from "../../../storage/local.lib";

const CONFIG = process.env.REACT_APP_CONFIG_FILE_NAME;

const createConfigContent = (fileId, withoutSub = REPEAT_WITHOUT_SUBTITLE_VALUE, withSub = REPEAT_WITH_SUBTITLE_VALUE) => {
    setValue(CONFIG_FILE_ID, fileId);
    setLocalStorageValues(withoutSub, withSub);
    updateFile(fileId, REPEAT_WITHOUT_SUBTITLE, withoutSub, "A1:B1").then(() => {
    });
    updateFile(fileId, REPEAT_WITH_SUBTITLE, withSub, "A2:B2").then(() => {
    });
}

const setLocalStorageValues = (withoutSub, withSub) => {
    setValue(REPEAT_WITHOUT_SUBTITLE, withoutSub);
    setValue(REPEAT_WITH_SUBTITLE, withSub);
}

const setDefaultConfigValues = (files) => {
    const file = files.find(file => file.name === CONFIG);
    if (file) {
        loadFile(file.id).then((response) => {
            const values = (response && response.result && response.result.values) ? response.result.values : [[]];
            const withoutSub = (values[0]) ? values[0][1] : REPEAT_WITHOUT_SUBTITLE_VALUE;
            const withSub = (values[1]) ? values[1][1] :REPEAT_WITH_SUBTITLE_VALUE;
            createConfigContent(file.id, withoutSub, withSub);
        });
    } else {
        create(CONFIG).then((response) => {
            createConfigContent(response.result.id);
        });
    }
}
export {setDefaultConfigValues};