import {list, loadGapi} from "../../../storage/gapi.lib";

import {setDefaultConfigValues} from "./config.lib";
import {setWordsFileValues} from "./words.lib";

const loadAccount = () => {
    loadGapi().then(() => list().then((response) => {
        const files = JSON.parse(response.body).files;
        setDefaultConfigValues(files);
        setWordsFileValues(files);
    }));
}

export default loadAccount;