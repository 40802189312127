import React from "react";

import ReactGA from 'react-ga';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

export default function Footer() {
    return (
        <div>
            ${ReactGA.pageview(window.location.pathname + window.location.search)};
        </div>
    )
}